import { useRef } from 'react';
import { useRouter } from 'next/router';
import { useIsomorphicLayoutEffect } from './use-isomorphic-layout-effect';
import { useIsPageVisible } from './use-is-page-visible';
import { restoreScrollPositions, saveScrollPositions } from '../utils/scroll-restoration';
import { sanitizeRouterPathname } from '../utils/sanitize-router-pathname';

export function useScrollRestoration() {
  const pathnameRef = useRef(typeof window === 'undefined' ? '' : window.location.pathname);
  const router = useRouter();
  const isPageVisible = useIsPageVisible();

  // Restore scroll positions on mount.
  useIsomorphicLayoutEffect(() => {
    if (typeof window === 'undefined') return;
    restoreScrollPositions(sanitizeRouterPathname(pathnameRef.current));
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (typeof window === 'undefined') return;

    const handleRouteChangeStart = (_pathname: string, { shallow }: { shallow: boolean }) => {
      if (shallow) return;
      saveScrollPositions(sanitizeRouterPathname(pathnameRef.current));
    };

    const handleRouteChangeComplete = (pathname: string, { shallow }: { shallow: boolean }) => {
      if (shallow) return;
      pathnameRef.current = pathname;
      restoreScrollPositions(sanitizeRouterPathname(pathname));
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router.events]);

  // Save scroll positions when the page is hidden.
  useIsomorphicLayoutEffect(() => {
    if (typeof window === 'undefined') return;
    if (isPageVisible) return;
    saveScrollPositions(sanitizeRouterPathname(pathnameRef.current));
  }, [isPageVisible]);
}
