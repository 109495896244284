import { SvgIconMemo, SvgIconProps } from './svg-icon';

export function PauseIcon({ viewBox = '0 0 32 32', ...restProps }: SvgIconProps) {
  return (
    <SvgIconMemo viewBox={viewBox} {...restProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 27H10.75C12.82 27 14.5 25.32 14.5 23.25V8.75C14.5 6.68 12.82 5 10.75 5H8.25C6.18 5 4.5 6.68 4.5 8.75V23.25C4.5 25.32 6.18 27 8.25 27ZM7 8.75C7 8.06 7.56 7.5 8.25 7.5H10.75C11.44 7.5 12 8.06 12 8.75V23.25C12 23.94 11.44 24.5 10.75 24.5H8.25C7.56 24.5 7 23.94 7 23.25V8.75ZM21.25 27H23.75C25.82 27 27.5 25.32 27.5 23.25V8.75C27.5 6.68 25.82 5 23.75 5H21.25C19.18 5 17.5 6.68 17.5 8.75V23.25C17.5 25.32 19.18 27 21.25 27ZM20 8.75C20 8.06 20.56 7.5 21.25 7.5H23.75C24.44 7.5 25 8.06 25 8.75V23.25C25 23.94 24.44 24.5 23.75 24.5H21.25C20.56 24.5 20 23.94 20 23.25V8.75Z"
        fill="currentColor"
      />
    </SvgIconMemo>
  );
}
