import { SvgIconMemo, SvgIconProps } from './svg-icon';

export function SpeakerOffIcon({ viewBox = '0 0 32 32', ...restProps }: SvgIconProps) {
  return (
    <SvgIconMemo viewBox={viewBox} {...restProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.03 26.7734C13.6886 27.2523 14.4667 27.5023 15.245 27.5034C15.2433 27.5034 15.2417 27.5034 15.24 27.5034H15.25C15.2483 27.5034 15.2467 27.5034 15.245 27.5034C15.8233 27.5026 16.4015 27.3626 16.94 27.0934C18.21 26.4534 19 25.1634 19 23.7434V8.25338C19 6.83338 18.21 5.54338 16.94 4.90338C15.68 4.26338 14.17 4.39338 13.03 5.23338L4.53 11.5034C3.57 12.2034 3 13.3334 3 14.5234V17.4934C3 18.6834 3.57 19.8134 4.53 20.5134L13.03 26.7734ZM14.51 7.25338C14.7386 7.08443 14.9869 7.00437 15.2452 7.00339C15.4336 7.00428 15.6316 7.05419 15.81 7.14338C16.24 7.36338 16.5 7.78338 16.5 8.26338V23.7534C16.5 24.2434 16.23 24.6534 15.81 24.8734C15.39 25.0934 14.9 25.0434 14.51 24.7634L6.01 18.5034C5.69 18.2634 5.5 17.8934 5.5 17.4934V14.5234C5.5 14.1234 5.69 13.7534 6.01 13.5134L14.51 7.25338ZM27.24 15.9434L30 18.7034L28.2 20.5034L25.44 17.7434L22.77 20.4234L21 18.6534L23.68 15.9834L21 13.3034L22.8 11.5034L25.48 14.1834L28.07 11.5834L29.84 13.3534L27.24 15.9434Z"
        fill="currentColor"
      />
    </SvgIconMemo>
  );
}
