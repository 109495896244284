import { ReactNode, memo } from 'react';
import { FooterMemo } from '../footer/footer';
import { PlayerQuestionnaireMemo } from '../player-questionnaire/player-questionnaire';
import { DetailModalMemo } from '../detail-modal/detail-modal';
import { useDynamicCssVariables } from '../../hooks/use-dynamic-css-variables';
import { useScrollRestoration } from '../../hooks/use-scroll-restoration';
import { useSyncRecentlyPlayed } from '../../hooks/use-sync-recently-played';
import { useSyncDebugFlags } from '../../hooks/use-sync-debug-flags';
import { useRecentlyPlayedRootClass } from '../../hooks/use-recently-played-root-class';
import { useHashNavigation } from '../../hooks/use-hash-navigation';
import styles from './page.module.css';

export interface PageProps {
  children: ReactNode;
}

export function Page({ children }: PageProps) {
  useHashNavigation();
  useDynamicCssVariables();
  useScrollRestoration();
  useSyncRecentlyPlayed();
  useSyncDebugFlags();
  useRecentlyPlayedRootClass();

  return (
    <>
      <main className={styles.content}>{children}</main>
      <FooterMemo />
      <PlayerQuestionnaireMemo />
      <DetailModalMemo />
    </>
  );
}

export const PageMemo = memo(Page);
