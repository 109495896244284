// The pathname in next router can include the query string and hash, so we need
// to parse the actual pathname from the provided value.
export function sanitizeRouterPathname(pathname: string) {
  try {
    // Let's parse the pathname with the URL constructor as the provided value
    // might contain the query string and/or hash, which we want to get rid of.
    // We need to provide a dummy URL to avoid throwing an error, note that we
    // are in the end just extracting the pathname from the URL.
    return new URL(`https://foo.com${pathname}`).pathname;
  } catch {
    // If the provided value is not a valid URL, we return it as is.
    return pathname;
  }
}
