import { ComponentProps, memo } from 'react';
import { InternalLinkMemo } from '../internal-link/internal-link';
import styles from './tag.module.css';

export interface TagProps extends Omit<ComponentProps<'span'>, 'ref'> {
  name?: string | null;
}

export function Tag({ name = '', className = '', ...restProps }: TagProps) {
  return (
    <span className={`${styles.root} ${className}`} {...restProps}>
      {name}
    </span>
  );
}

export const TagMemo = memo(Tag);

export interface AnchorTagProps extends Omit<ComponentProps<'a'>, 'ref' | 'href'> {
  href: ComponentProps<typeof InternalLinkMemo>['href'];
  name?: string | null;
}

export function AnchorTag({ name = '', className = '', ...restProps }: AnchorTagProps) {
  return (
    <InternalLinkMemo className={`${styles.root} ${className}`} {...restProps}>
      {name}
    </InternalLinkMemo>
  );
}

export const AnchorTagMemo = memo(AnchorTag);
