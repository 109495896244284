import { useCallback, useEffect, useRef, useState, useMemo, memo, PropsWithChildren } from 'react';
import { useQuery } from '@apollo/client';
import FocusTrap from 'focus-trap-react';
import { GetItemDetail } from '../../graphql-queries';
import { AnchorButtonMemo, ButtonMemo } from '../buttons/button/button';
import { ModalMemo } from '../modal/modal';
import { LoadingSpinnerMemo } from '../loading-spinner/loading-spinner';
import { MarkdownMemo } from '../markdown/markdown';
import { VideoPlayerMemo } from '../video-player/video-player';
import { AnchorTagMemo } from '../tag/tag';
import { CloseIcon } from '../svg-icons/close-icon';
import { useRouter } from 'next/router';
import { useIsomorphicLayoutEffect } from '../../hooks/use-isomorphic-layout-effect';
import { useStore } from '../../providers/store-provider';
import { sanitizeRouterPathname } from '../../utils/sanitize-router-pathname';
import {
  navSystem,
  ARROW_NAV_TARGET_CLASS,
  ARROW_NAV_TARGET_GROUP_CLASS,
  ARROW_NAV_STICKY_CLASS,
  ARROW_NAV_CONTAINER_CLASS,
  FOCUS_SCROLL_CONTAINER_ATTRIBUTE,
  FOCUS_SCROLL_DISABLED_ATTRIBUTE,
  ARROW_NAV_CONTAIN_ATTRIBUTE,
  ARROW_NAV_IGNORE_ATTRIBUTE,
} from '../../utils/nav-system';
import type { Options as FocusTrapOptions } from 'focus-trap';
import styles from './detail-modal.module.css';

const MODAL_CLOSE_TIMEOUT = 200;

type DetailModalData = {
  id: string;
  type: string;
  name: string;
  description: string;
  launchUrl: string;
  imageUrl: string;
  videoUrl?: string;
  categories?: { id: string; name: string }[];
  developer?: string;
  publisher?: string;
};

export function DetailModal() {
  const router = useRouter();
  const routerRef = useRef(router);
  const nextId = typeof router.query.info === 'string' ? router.query.info : '';
  const [currentId, setCurrentId] = useState<string>(nextId);
  const isModalOpen = Boolean(nextId && currentId);
  const isDetailOpen = isModalOpen && nextId === currentId;

  // Sync router ref with router.
  routerRef.current = router;

  const handleClose = useCallback(() => {
    const router = routerRef.current;

    // Try to go back in history if possible.
    if (window.history.length > 1) {
      router.back();
    }
    // Fallback for cases where we can't go back: remove info search param from
    //the URL.
    else {
      const { info, ...nextQuery } = router.query;
      router.replace(
        { pathname: sanitizeRouterPathname(router.pathname), query: nextQuery },
        undefined,
        {
          scroll: false,
          shallow: true,
        },
      );
    }
  }, []);

  // Sync current id with next id.
  useEffect(() => {
    // Nothing to do if we are not in the browser.
    if (typeof window === 'undefined') return;

    // Nothing to do if next id is the same as the current id.
    if (nextId === currentId) return;

    // If we have a current id we need to give time for the modal to close
    // before we can open the next one.
    if (currentId) {
      const timeout = window.setTimeout(() => {
        setCurrentId(nextId);
      }, MODAL_CLOSE_TIMEOUT);
      return () => {
        window.clearTimeout(timeout);
      };
    }

    // In other cases, let's just sync the current item with the next item.
    setCurrentId(nextId);
    return;
  }, [nextId, currentId]);

  return (
    <ModalMemo open={isModalOpen} onClose={handleClose}>
      {!!currentId && <ItemDetailMemo id={currentId} open={isDetailOpen} onClose={handleClose} />}
    </ModalMemo>
  );
}

function ItemDetail({ id, open, onClose }: { id: string; open: boolean; onClose: () => void }) {
  const { data, loading } = useQuery(GetItemDetail, { variables: { itemId: id } });
  const rawItemData = data?.items?.[0];
  const itemData = useMemo(() => {
    return rawItemData
      ? ({
          id: rawItemData.id,
          type: rawItemData.type || '',
          name: rawItemData.name || '',
          description: rawItemData.description || '',
          launchUrl: rawItemData.launchUrl || '',
          imageUrl: rawItemData.landscapeThumbnail?.url || '',
          videoUrl: rawItemData.trailerVideo?.url || rawItemData.backgroundVideo?.url || '',
          categories: rawItemData.categories?.map((category) => {
            return {
              id: category.id,
              name: category.name || '',
            };
          }),
          developer: rawItemData.developer || '',
          publisher: rawItemData.publisher || '',
        } as const)
      : null;
  }, [rawItemData]);

  return (
    <div className={styles.root}>
      {id && loading ? (
        <div className={styles.loader}>
          <LoadingSpinnerMemo active={open} />
        </div>
      ) : itemData ? (
        <DetailModalContentPanelMemo itemData={itemData} open={open} onClose={onClose} />
      ) : (
        <DetailModalErrorPanelMemo open={open} onClose={onClose} />
      )}
    </div>
  );
}

function DetailModalErrorPanel({ open, onClose }: { open: boolean; onClose: () => void }) {
  const [isPanelOpen, setPanelOpen] = useState(false);

  // Delay open state change to allow for the panel to animate in.
  useIsomorphicLayoutEffect(() => {
    if (typeof window === 'undefined') return;

    if (!open) {
      setPanelOpen(false);
      return;
    }

    let rafId = window.requestAnimationFrame(() => {
      rafId = window.requestAnimationFrame(() => {
        setPanelOpen(true);
      });
    });

    return () => {
      window.cancelAnimationFrame(rafId);
    };
  }, [setPanelOpen, open]);

  return (
    <DetailModalFocusTrap>
      <div
        className={`${styles.panel} ${styles.errorPanel} ${isPanelOpen ? styles.isPanelOpen : ''}`}
      >
        <p>
          Oh no, something went terribly wrong. We failed to fetch the data for some reason. Please
          make sure your internet connection is working normally and try again.
        </p>
        <ButtonMemo
          className={`${styles.closeButton} ${ARROW_NAV_TARGET_CLASS}`}
          theme="SecondaryLight"
          onClick={onClose}
        >
          <CloseIcon />
        </ButtonMemo>
      </div>
    </DetailModalFocusTrap>
  );
}

function DetailModalContentPanel({
  itemData,
  open,
  onClose,
}: {
  itemData: DetailModalData;
  open: boolean;
  onClose: () => void;
}) {
  const panelRef = useRef<HTMLDivElement>(null);
  const panelContentRef = useRef<HTMLDivElement>(null);
  const [isPanelOpen, setPanelOpen] = useState(false);
  const addRecentlyPlayedId = useStore((state) => state.addRecentlyPlayedId);

  // Delay open state change to allow for the panel to animate in.
  useIsomorphicLayoutEffect(() => {
    if (typeof window === 'undefined') return;

    if (!open) {
      setPanelOpen(false);
      return;
    }

    let rafId = window.requestAnimationFrame(() => {
      rafId = window.requestAnimationFrame(() => {
        setPanelOpen(true);
      });
    });

    return () => {
      window.cancelAnimationFrame(rafId);
    };
  }, [setPanelOpen, open]);

  // Track panel content scrollbar width.
  useIsomorphicLayoutEffect(() => {
    if (typeof window === 'undefined') return;

    const panel = panelRef.current;
    const panelContent = panelContentRef.current;
    if (!panel || !panelContent) return;

    const computeScrollbarWidth = () => {
      const scrollbarWidth = panelContent.offsetWidth - panelContent.clientWidth;
      panel.style.setProperty('--app-modal--scrollbar-width', `${scrollbarWidth}px`);
    };

    computeScrollbarWidth();
    window.addEventListener('resize', computeScrollbarWidth);

    return () => {
      window.removeEventListener('resize', computeScrollbarWidth);
    };
  }, [itemData]);

  if (!itemData) return null;

  return (
    <DetailModalFocusTrap>
      <div
        ref={panelRef}
        className={`${styles.panel} ${styles.contentPanel} ${isPanelOpen ? styles.isPanelOpen : ''}`}
      >
        <ButtonMemo
          className={`${styles.closeButton} ${ARROW_NAV_TARGET_CLASS}`}
          theme="SecondaryDark"
          onClick={onClose}
          {...{
            [FOCUS_SCROLL_DISABLED_ATTRIBUTE]: '',
          }}
        >
          <CloseIcon />
        </ButtonMemo>
        <div ref={panelContentRef} className={`${styles.content} ${ARROW_NAV_CONTAINER_CLASS}`}>
          <div
            className={`${styles.videoPlayerContainer} ${ARROW_NAV_CONTAINER_CLASS} ${ARROW_NAV_TARGET_GROUP_CLASS}`}
            {...{
              [ARROW_NAV_CONTAIN_ATTRIBUTE]: 'x',
              [ARROW_NAV_IGNORE_ATTRIBUTE]: 'y',
              [FOCUS_SCROLL_CONTAINER_ATTRIBUTE]: '',
            }}
          >
            <VideoPlayerMemo
              className={styles.videoPlayer}
              videoSrc={itemData.videoUrl || ''}
              posterSrc={itemData.imageUrl || ''}
              posterAlt={itemData.name || ''}
              posterPriority={true}
              posterSizes="(max-width: 1090px) 100vw, 950px"
            />
          </div>
          <div className={styles.info}>
            <div className={styles.infoRight}>
              <div className={styles.infoRightSticky}>
                <AnchorButtonMemo
                  className={`${styles.launchAction} ${ARROW_NAV_TARGET_CLASS}`}
                  theme="Primary"
                  href={itemData.launchUrl}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => addRecentlyPlayedId(itemData.id)}
                >
                  {itemData.type === 'game' ? 'Play' : 'Launch'}
                </AnchorButtonMemo>
              </div>
            </div>
            <div
              className={`${styles.infoLeft} ${ARROW_NAV_TARGET_CLASS} ${ARROW_NAV_STICKY_CLASS}`}
              tabIndex={0}
              aria-label="info"
            >
              <h2 className={styles.title}>{itemData.name}</h2>
              {!!itemData.description && (
                <MarkdownMemo className={styles.description}>{itemData.description}</MarkdownMemo>
              )}
              <dl className={styles.metadata}>
                {itemData.developer && (
                  <>
                    <dt>Developer</dt>
                    <dd>{itemData.developer}</dd>
                  </>
                )}
                {itemData.publisher && (
                  <>
                    <dt>Publisher</dt>
                    <dd>{itemData.publisher}</dd>
                  </>
                )}
              </dl>
              {!!itemData.categories?.length && (
                <div className={styles.categories}>
                  {itemData.categories.map((category) => (
                    <AnchorTagMemo
                      key={category.id}
                      name={category.name}
                      href={`/category/${category.id}`}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </DetailModalFocusTrap>
  );
}

function DetailModalFocusTrap({ children }: PropsWithChildren<{}>) {
  const options = useMemo<FocusTrapOptions>(() => {
    return {
      initialFocus: () => (navSystem.getNavDevice() === 'keyboard' ? undefined : false),
      allowOutsideClick: true,
      returnFocusOnDeactivate: true,
      preventScroll: false,
      escapeDeactivates: false,
      isKeyForward: (e) => {
        if (e.key === 'Tab' && !e.shiftKey) {
          if (navSystem.getPreviousNavDevice() === 'keyboard') {
            return true;
          }
        }
        return false;
      },
      isKeyBackward: (e) => {
        if (e.key === 'Tab' && e.shiftKey) {
          if (navSystem.getPreviousNavDevice() === 'keyboard') {
            return true;
          }
        }
        return false;
      },
    };
  }, []);

  return <FocusTrap focusTrapOptions={options}>{children}</FocusTrap>;
}

const ItemDetailMemo = memo(ItemDetail);
const DetailModalErrorPanelMemo = memo(DetailModalErrorPanel);
const DetailModalContentPanelMemo = memo(DetailModalContentPanel);

export const DetailModalMemo = memo(DetailModal);
