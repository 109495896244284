import { SvgIconMemo, SvgIconProps } from './svg-icon';

export function BulletDiamondIcon({ viewBox = '0 0 12 12', ...restProps }: SvgIconProps) {
  return (
    <SvgIconMemo viewBox={viewBox} {...restProps}>
      <path
        d="M4.93934 1.06066C5.52513 0.474874 6.47487 0.474874 7.06066 1.06066L10.9393 4.93934C11.5251 5.52513 11.5251 6.47487 10.9393 7.06066L7.06066 10.9393C6.47487 11.5251 5.52513 11.5251 4.93934 10.9393L1.06066 7.06066C0.474874 6.47487 0.474874 5.52513 1.06066 4.93934L4.93934 1.06066Z"
        fill="currentColor"
      />
    </SvgIconMemo>
  );
}
