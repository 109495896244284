import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { clearScrollPositions } from '../utils/scroll-restoration';
import { sanitizeRouterPathname } from '../utils/sanitize-router-pathname';

export function useHashNavigation() {
  const router = useRouter();
  const routerRef = useRef(router);

  // Sync router ref with router.
  routerRef.current = router;

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const onHashChange = () => {
      const { hash } = window.location;

      // If the hash starts with `#nav-` we assume that the part coming after it
      // is the pathname we want to navigate to.
      if (hash.startsWith('#nav-')) {
        // Get the pathname from the hash.
        const pathname = hash.slice(5);
        // "index" is a special case, we want to navigate to the root.
        const inferredPathname = pathname === 'index' ? '/' : `/${pathname}`;

        // If we are already on the requested pathname we don't want to do
        // anything, treat it as a no-op. So we need to get rid of the history
        // entry caused by the hash change and go back to the previous entry.
        if (inferredPathname === sanitizeRouterPathname(routerRef.current.pathname)) {
          routerRef.current.back();
          return;
        }

        // In other cases, we need to reset the scroll restoration data for the
        // inferred pathname and navigate to it (replacing the history entry).
        clearScrollPositions(inferredPathname);
        routerRef.current.replace({
          pathname: inferredPathname,
          hash: '',
        });
      }
    };

    window.addEventListener('hashchange', onHashChange);

    return () => {
      window.removeEventListener('hashchange', onHashChange);
    };
  }, []);
}
